import { Component, OnInit } from '@angular/core';

declare var hbspt: any;

@Component({
  selector: 'about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})

export class AboutComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }
  ngAfterViewInit(){
    hbspt.forms.create({
    portalId: "4555522",
    formId: "28a8cc9f-78a0-4947-ba67-f8004f5129a2",
    target: "#hubspotForm"
    });
    window.scrollTo(0, 0); // used this because the scroll position is not at the top after inserting the form, so i'm manually pushing it :wink:
    }
}
