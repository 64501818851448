import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss']
})
export class FeaturesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    // finish implementation
    // https://www.sitepoint.com/build-javascript-countdown-timer-no-dependencies/
    // let date = new Date();
    // if (date.getMonth() < 9 || (date.getMonth() === 9 && date.getDay() <== 14))
    // {
    //   date = new Date(date.getFullYear(), 9, 15);
    // }
    // else if (date.getMonth() === 11 && date.getDate() > 7)
    // {
    //   date = new Date(date.getFullYear() + 1, 9, 15);
    // }
    // else
    // {
    //   date = new Date(date.getFullYear(), 11, 7);
    // }

    // this.initializeClock('clockdiv', date);
  }

  // initializeClock(id, endtime) {
  //   const clock = document.getElementById(id);
  //   const timeinterval = setInterval(() => {
  //     const t = this.getTimeRemaining(endtime);
  //     clock.innerHTML = 'days: ' + t.days + '<br>' +
  //                       'hours: '+ t.hours + '<br>' +
  //                       'minutes: ' + t.minutes + '<br>' +
  //                       'seconds: ' + t.seconds;
  //     if (t.total <= 0) {
  //       clearInterval(timeinterval);
  //     }
  //   },1000);
  // }

  // getTimeRemaining(endtime){
  //   const total = Date.parse(endtime) - Date.parse(new Date());
  //   const seconds = Math.floor( (total/1000) % 60 );
  //   const minutes = Math.floor( (total/1000/60) % 60 );
  //   const hours = Math.floor( (total/(1000*60*60)) % 24 );
  //   const days = Math.floor( total/(1000*60*60*24) );
  
  //   return {
  //     total,
  //     days,
  //     hours,
  //     minutes,
  //     seconds
  //   };
  // }

}
